import React, {useState, useEffect, useMemo} from 'react';
import AppSettings from '../../../appsettings';
import DropdownV2 from '../../../components/molecules/dropdownV2';
import { isDesktopView, isTabletView, isMobileView } from '../../utils/getScreenSize';
import '../../../styles/css/trimComparisonComponent.css';
import { selectDropdown, selectComparison } from './featuresSpecs.analytics';

const TrimComparisonComponent = (props) => {
    const {
        comparisonActive,
        setComparisonActive,
        comparisonYears,
        s3PathName,
        selectedTrim,
        secondSelectedTrim,
        setSecondSelectedTrim,
        secondSelectedYear,
        setSecondSelectedYear,
        secondTrimsOptions,
        year,
        analyticsData
      } = props;

    const [firstSelectedImage, setFirstSelectedImage] = useState('');
    const [secondSelectedImage, setSecondSelectedImage] = useState('');
   
    const getHasMultiplePowerTrainGroups = () => {
        const powertrainCategories = [...new Set(
            secondTrimsOptions
              .flatMap(trim => trim.trimModels && trim.trimModels
              .map(model => model.powertrainCategory)
              .filter(powertrainCategory => powertrainCategory != null)
        ))];
        return props.secondTrimsOptions && powertrainCategories.length && powertrainCategories.length !== 1 ? true : false;
    }

    const getTrimName = () => {
        const powertrainCategory = secondSelectedTrim?.trimModels?.find(e => e?.powertrainCategory)?.powertrainCategory;
        return powertrainCategory === undefined || !getHasMultiplePowerTrainGroups() || !AppSettings.FeatureFlags.showTrimsUpdate ? secondSelectedTrim?.name : secondSelectedTrim?.name + ' (' + powertrainCategory + ')';
    }

    const secondSelectedTrimOptions = useMemo(() => {
            const hasAttribute = secondTrimsOptions.some(trim => trim.trimModels && trim.trimModels.some(model => model.powertrainCategory));
            if (!hasAttribute || !getHasMultiplePowerTrainGroups() || !AppSettings.FeatureFlags.showTrimsUpdate) {
              return secondTrimsOptions.map(t => t.name);
            };
        
            const grouped = secondTrimsOptions.reduce((acc, trim) => {
              trim.trimModels.forEach(model => {
                if (model.powertrainCategory) {
                    if (!acc[model.powertrainCategory]) {
                        acc[model.powertrainCategory] = new Set();
                    }
                    const trimNameAndPowertrainCategory = {
                        value: `${trim.name} ${model.powertrainCategory}`,
                        label: trim.name
                    };
                    
                    for (let item of acc[model.powertrainCategory]) {
                        if(item.value === trimNameAndPowertrainCategory.value) {
                            return;
                        }
                    }
                    acc[model.powertrainCategory].add(trimNameAndPowertrainCategory);
                }
              });
              return acc;
            }, {});

            return Object.keys(grouped).map(powertrainCategory => ({
                type: 'group',
                name: powertrainCategory,
                items: Array.from(grouped[powertrainCategory])
            }));
        
 
    }, [secondSelectedTrim, secondTrimsOptions]);

    useEffect(() => {
        setFirstSelectedImage(`${AppSettings.AWSImgRepo.vehicle}${props.year}/${s3PathName}/en/${selectedTrim?.trim?.trimImageFilename}`)
    }, [props.s3PathName, props.year, props.selectedTrim.trim])

    useEffect(() => {
        setSecondSelectedImage(`${AppSettings.AWSImgRepo.vehicle}${secondSelectedYear}/${s3PathName}/en/${secondSelectedTrim?.trimImageFilename}`)
    }, [props.s3PathName, props.year, props.secondSelectedTrim])

    const CloseButton = () => (
        <div className='trim-comparison-close' onClick={() => {
            setComparisonActive(false);
            setSecondSelectedTrim(secondTrimsOptions[0]);
            setSecondSelectedYear(comparisonYears[0]);
            sendComparisonAnalytics(false);
        }}>
            <img src={AppSettings.AWSImgRepo.resources + 'en/minus-circle-black.svg'} />
        </div>
    );

    function sendComparisonAnalytics(isOpen){
        selectComparison({
            page: analyticsData?.page ? analyticsData.page : '', 
            isOpen: isOpen, 
            modelName: analyticsData?.modelName,
            modelYear: analyticsData?.modelYear, trim: props?.analyticsData?.trim
        });
    }

    function sendYearAnalytics(selectedYear) {
        selectDropdown({
            page: analyticsData?.page ? analyticsData.page : '',
            label: 'Model Year Select',
            contentTitle: selectedYear,
            modelName: analyticsData?.modelName,
            modelYear: analyticsData?.modelYear,
            trim: props?.analyticsData?.trim
        })
    }


    function sendTrimAnalytics(selectedTrim) {
        selectDropdown({
            page: analyticsData?.page ? analyticsData.page : '',
            label: 'Trim Select',
            contentTitle: selectedTrim,
            modelName: analyticsData?.modelName,
            modelYear: analyticsData?.modelYear,
            trim: props?.analyticsData?.trim
        })
    }

    if (comparisonActive && selectedTrim.trim) {
        return (
            <div className='trim-comparison-container'>
                <div className='trim-comparison-first'>
                    <div className='trim-comparison-first-image'>
                        <img 
                            src={firstSelectedImage} 
                            onError={() =>
                                setFirstSelectedImage(
                                    encodeURI(
                                    AppSettings.AWSImgRepo.resources + 'en/image_coming_soon_placeholder.png'
                                    ).toString()
                                )
                            }
                        />
                    </div>
                    <div className='trim-comparison-text'>
                        <span className='trim-comparison-year'>{year + ' '}</span>
                        <span className='trim-comparison-level'>{selectedTrim?.trim?.name}</span>
                    </div>
                </div>
                <div className='trim-comparison-divider'></div>
                <div className='trim-comparison-second'>
                    <div className='img-button-group'>
                        {isMobileView(window.innerWidth) && <CloseButton />}
                        <div className='trim-comparison-second-image'>
                            <img 
                                src={secondSelectedImage} 
                                onError={() =>
                                    setSecondSelectedImage(
                                        encodeURI(
                                        AppSettings.AWSImgRepo.resources + 'en/image_coming_soon_placeholder.png'
                                        ).toString()
                                    )
                                }
                            />
                        </div>
                        {isTabletView(window.innerWidth) && <CloseButton />}
                    </div>
                    
                    <div className='trim-comparison-dropdowns'>
                        <DropdownV2
                            style='white'
                            options={comparisonYears}
                            rootClassName='trim-comparison-dropdown-menu'
                            value={secondSelectedYear.toString()}
                            onValueChange={(selection) => {
                                setSecondSelectedYear(selection.value);
                                sendYearAnalytics(selection.value);
                            }}
                        />
                        <DropdownV2
                            style='white'
                            options={secondSelectedTrimOptions}
                            rootClassName='trim-comparison-dropdown-menu'
                            value={{label: getTrimName()}}
                            onValueChange={(selection) => {

                                if (!getHasMultiplePowerTrainGroups() || !AppSettings.FeatureFlags.showTrimsUpdate) {
                                    const selectedData = secondTrimsOptions.find(t => t.name === selection.value);
                                    setSecondSelectedTrim(selectedData);
                                    sendTrimAnalytics(selectedData.name);
                                } else {
                                    const selectedData = secondTrimsOptions.find(t => t.name === selection.label);
                                    const selectedPowertrain = selection.value.replace(selectedData.name, '').trim();

                                    const filteredSelectedData = {
                                        ...selectedData,
                                        trimModels: selectedData.trimModels.filter(model => model.powertrainCategory === selectedPowertrain),
                                    }
                                    setSecondSelectedTrim(filteredSelectedData);
                                    sendTrimAnalytics(filteredSelectedData.name);
                                }
                            }}
                        />
                    </div>
                    {isDesktopView(window.innerWidth) && <CloseButton />}
                </div>
            </div>
        );
    }
    return (
        <div className='trim-comparison-open' onClick={() => { setComparisonActive(true); sendComparisonAnalytics(true); }}>
            <div className='trim-comparison-icon'>
                <img src={AppSettings.AWSImgRepo.resources + 'en/plus-circle-black.svg'} />
            </div>
            <div className='trim-comparison-text'>Add Second Trim to Compare</div>
        </div>
    );
};

export default TrimComparisonComponent;
