import { useMemo, useRef } from "react";
import * as _ from "lodash";
import AppSettings from '../../../appsettings';
import { getHasMultiplePowerTrainGroups } from "../../VPRouter/VPUtils.js";
import TitleText from "../../../components/atoms/TitleText";
import { Accordion } from "../../../components/molecules/Accordion";
import Skeleton from "react-loading-skeleton";

import * as analyticFunctions from "./modelOverviewV2Sections.analytics";
import DisclaimersComponent from "../../disclaimers/disclaimers.component";

import "../../../styles/css/model-overview-v2/model-code-pricing-accordion.css";

const ModelCodePricingAccordion = (props) => {
  const { trims, modelInfos, model, year } = props;
  const disclaimerComponentRef = useRef();

  const renderTrimModelPrices = (modelName) => {
    const modelTrimOptions = trims?.filter((trim) => trim.name === modelName);
    const hasPowertrain = modelTrimOptions.some((item) => item?.powertrainCategory);
    if (hasPowertrain && getHasMultiplePowerTrainGroups(props?.trims) && AppSettings.FeatureFlags.showTrimsUpdate) {
      const groups = modelTrimOptions.reduce((acc, item) => {
        const powertrainCategory = acc.find((group) => group.name === item.powertrainCategory);
        if (!powertrainCategory) {
          acc.push({
            name: item.powertrainCategory,
            items: [
              <li>
                <div>
                  <b>{item?.modelCode}</b>
                  <p>{item?.fullName}</p>
                </div>
                <span>
                  {item?.msrp
                    ? parseFloat(item?.msrp).toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })
                    : "Base MSRP Unavailable"}
                  <sup className="disclaimer-link" onClick={() => disclaimerComponentRef.current.onOpenModal()}>&dagger;</sup>
                </span>
              </li>
            ],
          });
        } else {
          powertrainCategory.items.push(<li>
            <div>
              <b>{item?.modelCode}</b>
              <p>{item?.fullName}</p>
            </div>
            <span>
              {item?.msrp
                ? parseFloat(item?.msrp).toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })
                : "Base MSRP Unavailable"}
              <sup className="disclaimer-link" onClick={() => disclaimerComponentRef.current.onOpenModal()}>&dagger;</sup>
            </span>
          </li>);
        }
        return acc;
      }, []);

      return (
        <ul className="trim-model-prices">
          {groups?.map((group) => (
            <li className="column-oriented">
              <b className="powertrain-name">{group.name}</b>
              <ul>{group.items}</ul>
            </li>
          ))}
        </ul>
      );
    }

    return (
      <ul className="trim-model-prices">
        {modelTrimOptions?.map((item) => (
          <li>
            <div>
              <b>{item?.modelCode}</b>
              <p>{item?.fullName}</p>
            </div>
            <span>
              {item?.msrp
                ? parseFloat(item?.msrp).toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })
                : "Base MSRP Unavailable"}
                <sup className="disclaimer-link" onClick={() => disclaimerComponentRef.current.onOpenModal()}>&dagger;</sup>
            </span>
          </li>
        ))}
      </ul>
    );
  };

  const uniqueTrims = useMemo(() => {
    return _.uniqBy(trims, "name");
  }, [trims]);

  const accordionRows = useMemo(() => {
    return uniqueTrims?.map((trim, index) => ({
      title: trim?.name,
      body: renderTrimModelPrices(trim?.name),
      key: index,
    }));
  }, [uniqueTrims, trims]);

  return (
    <div className="model-code-pricing-wrapper">
      <div className="model-code-pricing-section">
        <TitleText className="section-title">Model Code Pricing</TitleText>
        {trims ? (
          <Accordion
            rows={accordionRows}
            className="model-code-pricing-accordion"
            termAnalyticsClick={(term, isOpen, rowAnalyticsData) => {
              analyticFunctions.modelCodePricingAccordionClick({
                trimName: term,
                isOpen,
                ...modelInfos,
              });
            }}
          />
        ) : (
          <Skeleton height={35} count={7} />
        )}
      </div>
      <DisclaimersComponent
        template={`${year}_${model}_overview`}
        ref={disclaimerComponentRef}
        addBaseMSRPDisclaimer={true}
      />
    </div>
  );
};

export default ModelCodePricingAccordion;
